.form-control {
    height: 48px;
    box-shadow: none;
}


.invalid-field {
    .invalid-feedback {
        display: block;
    }
}

.invalid-feedback {
    color: $danger;
    font-size: 13px;
    p {
        color: inherit;
        font-size: inherit;
    }
}

.app-label {
    position: relative;
    label {
        color: $body-color;
        padding: 0 5px;
        position: absolute;
        top: 11px;
        left: 10px;
        cursor: text;
        transition: all .25s ease-out .05s;
        -webkit-transition: all .25s ease-out .05s;
        cursor: text;
    }
    .form-control {
        background: none;
        &:focus {
            box-shadow: none;
            border-color: $primary;
            &+label {
                top: -11px;
                background: $white;
                transform: scale(0.9);
                color: $primary;
            }
        }
    }
    &.up {
        .form-control {
            border-color: $primary;
        }
        label {
            top: -11px;
            background: $white;
            transform: scale(0.9);
            color: $primary;
        }
    }
    &.invalid-field {
        .form-control {
            border-color: $danger;
            &:focus {
                border-color: $primary;
            }
        }
        label {
            top: -11px;
            background: $white;
            transform: scale(0.9);
            color: $danger;
        }
    }
}

textarea.form-control {
    height: auto !important;
}

.error {
    margin: 8px 0px;
    display: none;
    color: red;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}