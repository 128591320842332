
.subscribe-form {
  h3 {
    color: $white;
    margin: 0px 0px 30px 0px;
  }

  form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
  }

  input {
    padding: 0 100px 0 30px;
    width: 100%;
    height: 50px;
    font-size: 17px;
    color: $white;
    border: none;
    outline: none !important;
    background-color: rgba($white, 0.19);
    border-radius: 30px;

    &::placeholder {
      color: rgba($white, 0.4);
    }
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    outline: none !important;
    border: none;
    color: $white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    color: $primary;
  }

}