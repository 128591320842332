
.pricing-column {
  position: relative;
}

.pricing-column {
  .inner-box {
    position: relative;
    padding: 30px;
    border: 1px solid $gray-600;
    border-radius: 8px;
    margin: 0 auto;
  }
}

.inner-box {
  p {
    padding: 0px 20px;
    text-align: center;
    font-size: $font-size-base;
    line-height: 26px;
    color: $gray-600;
    margin-bottom: 30px;
  }

  &.active,
  &:hover {
    border-color: $primary;
    .plan-title {
      color: $primary;
    }
    .shadow-icon {
      i {
        color: $white;
        background: $primary;
      }
    }
  }
}


.pricing-column {
  .plan-header {
    position: relative;
  }

  .plan-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .plan-price {
    margin-bottom: 10px;
  }

  .plan-duration {
    font-size: 13px;
    color: $gray-500;
  }

  .plan-stats {
    position: relative;
    padding: 30px 0px 15px;

    li {
      margin-bottom: 15px;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        font-size: 18px;
        width: 26px;
        vertical-align: middle;
      }
    }

  }
}