.home {
  padding-top: 180px;
  padding-bottom: 150px;
  position: relative;
  .sup {
    font-size: 16px;
  }
  &.pt-150 {
    padding-top: 150px;
  }
  .subscribe-form {
    @media (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 768px) {
      max-width: 80%;
    }
  }
}

.home-1 {
  z-index: 0;
  display: flex;
  align-items: center;
  h1 {
    font-size: 64px;
    line-height: 72px;
    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 60px;
    }
    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 48px;
    }
  }
}


.home-3 {
  h1 {
    font-size: 48px;
    line-height: 60px;
    @media (max-width: 1024px) {
      font-size: 40px;
      line-height: 52px;
    }
  }
}

.home-3-intro-form {
  background-color: $white;
  padding: 30px;
  border-radius: 5px;
  border: 3px solid $gray-200;

  h4 {
    margin-bottom: 30px;
  }

  input {
    border: 1px solid $gray-200;
    height: 44px;
    box-shadow: none !important;
  }
  label {
    top: 8px;
  }
}


#typewriter{
  min-width: 80px;
  display: inline-block;
}

.home-4 {
  padding-bottom: 100px;
  margin-bottom: 8rem;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    z-index: 0;
    border: 0 solid transparent;
    border-left-width: 100vw;
    border-bottom-width: 4rem;
    bottom: -4rem;
    border-left-color: $dark;
  }
}

.home-5 {
  padding-top: 200px;
}

.home-4-outer-section {
  position: relative;
  margin-bottom: -14rem;
  z-index: 1;
}

/* home 1 */
.home-shape {
  .shape1 {
    position: absolute;
    top: 55%;
    left: 2%;
    -webkit-animation: animationFramesOne 15s linear infinite;
    animation: animationFramesOne 15s linear infinite;
    z-index: -1;
  }
  .shape2 {
    position: absolute;
    top: 19%;
    left: 3%;
    -webkit-animation: pulse 4s infinite;
    animation: pulse 4s infinite;
    z-index: -1;
  }
  .shape3 {
    position: absolute;
    bottom: 13%;
    left: 40%;
    -webkit-animation: rotate360 6s infinite linear;
    animation: rotate360 6s infinite linear;
    z-index: -1;
  }
  .shape4 {
    position: absolute;
    top: 15%;
    left: 35%;
    -webkit-animation: rotate360 6s infinite linear;
    animation: rotate360 6s infinite linear;
    z-index: -1;
  }
  .shape5 {
    position: absolute;
    top: 15%;
    left: 62%;
    -webkit-animation: animationFramesOne 15s linear infinite;
    animation: animationFramesOne 15s linear infinite;
    z-index: -1;
  }
  .shape6 {
    position: absolute;
    bottom: 30%;
    right: 4%;
    -webkit-animation: animationFramesOne 15s linear infinite;
    animation: animationFramesOne 15s linear infinite;
    z-index: -1;
  }
}

/* home 7,9,10 */
.home-one-slider {
  margin-top: -160px;
  padding-top: 60px;
}

/* home 7,8,9,10 */
.home-alt {
  p {
    color: $gray-200 !important;
  }
  h1 {
    color: $white;
    font-size: 64px;
    margin-bottom: 40px;
    line-height: 72px;
    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 60px;
    }
    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 48px;
    }
  }
}

/* home 8 carousel */
.home-carousel {
  .home-alt {
    padding: 230px 0 150px;
    @media (max-width: 579px) {
      padding: 180px 30px 100px;
    }
  }
  .carousel-item {
    background-position: center !important;
    background-size: cover !important;
  }
}

@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

/* inner page home */
.inner-home {
  padding-top: 160px;
  padding-bottom: 100px;
  h1 {
    color: $white;
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 54px;
    font-weight: $font-weight-semibold;
  }
}