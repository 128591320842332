.blog {
  border-radius: 8px;
  overflow: hidden;
  background: $white;
  .meta {
    display: flex;
    li {
      display: flex;
      align-items: center;
      i {
        color: $primary;
      }
      &:after {
        content: "|";
        display: inline-block;
        margin: 0 10px;
      }
      &:last-child:after {
        display: none;
      }
    }
  }
  .blog-body{
    padding: 30px;
    h5 {
      a {
        color: $heading-color;
        font-weight: 500;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.widget {
  padding: 30px;
  box-shadow: $box-shadow;
  border-radius: 8px;
  .widget-title {
    padding-bottom: 8px;
    border-bottom: 1px solid $dark;
    margin-bottom: 30px;
  }
}

.widget-search {
  .input-group {
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 4px;
    .form-control {
      border: none;
    }
    .btn {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: .25rem;
      background: #fff;
      min-width: 50px;
      font-size: 18px;
      &:hover {
        box-shadow: none;
      }
    }
  }
}

.widget-category-list {
  li {
    border-bottom: 1px solid $border-color;
    padding: 0.5rem 0;

    a {
      display: flex;
      justify-content: space-between;

      color: $body-color;
      &:hover {
        color: $primary;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

.widget-post {
  .media {
    margin-bottom: 15px;

    img {
      width: 80px;
    }

    .media-body {
      margin-left: 0.75rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .media-body {
    a {
      display: block;
      color: $body-color;

      &:hover {
        color: $primary;
      }
    }
  }
}

.insta-feeds {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;

  li {
    margin-bottom: 10px;
    padding: 0 5px;
    width: 33.33%;
    max-width: 33.33%;
    display: block;
    border-radius: 4px;
    overflow: hidden;

    a {
      display: block;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.widget-tags {
  li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }
}