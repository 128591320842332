
.swiper-slider-button {
  display: flex;
  justify-content: center;
  & > * {
    font-size: 24px;
    color: $white;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    border: 1px solid $primary;
    &:focus {
      outline: none;
    }
  }
  .slider-button-prev {
    margin-right: 10px;
  }
  .swiper-button-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
