.remix-icon {
  display: flex;
  justify-content: center;
  i {
    font-size: 32px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: $primary;
      background: rgba($primary, 0.2);
  }
  i:after {
    transition: transform 0.25s;
  }
}

.features-box {
  box-shadow: 0 .25rem 1.75rem rgba(30,34,40,.07);
  padding:45px;
  border-radius: 8px;
  background: $white;
  border-bottom: 5px solid rgba($primary, 0.6);
  transition: all 0.3s ease-in-out;
  @media (max-width: 767px) {
    padding: 30px;
  }
  .remix-icon {
    justify-content: flex-start;
  }
  p {
    width: 100%;
    margin-bottom: 0;
  }
  &:hover {
    transform: translateY(-10px);
  }
}

@each $name, $value in $colors {
  .feature-#{$name} {
    .remix-icon {
      i {
        color: $value;
        background: rgba($value, 0.2);
      }
    }
    &:hover {
      .remix-icon {
        i {
          color: $white;
          background: $value;
        }
      }
    }
  }
  .features-box-#{$name} {
    border-bottom-color: rgba($value, 0.6);
  }
}

.features-box-2 {
  @media (min-width: 992px){
    padding: 0 15px;
  }
  p {
    width: 85%;
    margin: 0 auto;
  }
  &:hover {
    i {
      color: $white;
      background: $primary;
    }
  }
}

@each $name, $value in $colors {
  .features-box-2-#{$name} {
    i {
      color: $value;
    }
    &:hover {
      i {
        color: $white;
        background: $value;
      }
    }
  }
}


.features-box-3 {
  transition: all 0.3s;
  h3 {
    font-size: 18px;
  }

  p {
    width: 85%;
    margin: 0 auto;
  }

  &:hover {
    transform: translateY(-10px);
    .bg-soft-primary {
      background: $primary !important;
    }
    .text-primary {
      color: $white !important;
    }
  }
}


.features-box-4 {
  transition: all 0.3s;
  padding: 30px;
  box-shadow: $box-shadow;
  border-radius: 8px;
  transition: all 0.3s;
  background: $white;
  border-bottom: 5px solid transparent;
  .icon {
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 1;
    @media (max-width: 767px) {
        font-size: 24px;
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
}

@each $name, $value in $colors {
  .features-box-4-#{$name} {
    border-bottom-color: rgba($value, 0.6);
    i {
      color: $value;
    }
    .link {
      color: $value;
      &:after {
        background-color: $value;
      }
    }
  }
}

.features-icon-box {
  position: relative;
  overflow: hidden;
  background: $light;
  i {
    color: $primary;
  }
  h3 {
    font-size: 18px;
    color: $body-color;
  }
  .big-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    opacity: 0.06;
    font-size: 120px;
    &.icon-right {
      top: 10%;
      right: -20px;
      left: auto;
    }
  }
  &:hover {
    background: rgba($primary, 0.9);
    color: rgba($white, 0.5);
    i {
      color: rgba($white, 0.5);
    }
    h3 {
      color: $white;
    }
  }
}


.feature-position-icon {
  position: absolute;
  top: -30px;
}

.more-features {
  .sub-feature {
    display: flex;
    flex-wrap: wrap;

    .media {
      .remix-icon {
        i:after {
          transition: transform 0.25s;
        }
      }

      &:hover {
        .remix-icon {
          i:after {
            transform: rotate(135deg);
          }
        }
      }
    }
  }
}

.item-list-right {
  &.item-list-big {
    li {
      padding: 0 70px 30px 0;
      list-style: none;
      @media (max-width: 991px) {
        padding: 0 0 30px 70px;
      }
    }
  }

  li {
    padding: 0 60px 20px 0;
    position: relative;
    text-align: right;
    .number,
    .feature-position-icon {
      right: 0;
      top: 0;
      @media (max-width: 991px) {
        left: 0;
        right: auto;
      }
    }
    
    @media (max-width: 991px) {
      text-align: left;
    }
  }
}

.item-list-left {
  &.item-list-big {
    li {
      padding: 0 0px 30px 70px;
      list-style: none;
    }
  }

  li {
    position: relative;
    .number,
    .feature-position-icon {
      left: 0;
      top: 0;
    }
  }
}

.item-list-big {
  .number {
    height: 42px;
    width: 42px;
    line-height: 32px;
    color: $white;
    background: $primary;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    border: 5px solid rgba($white, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
  }
}
