
@for $i from 3 to 7 {

  .mb-#{$i*5},
  .my-#{$i*5} {
    margin-bottom: $i*5px;
  }

  .mt-#{$i*5},
  .my-#{$i*5} {
    margin-top: $i*5px;
  }

  .ms-#{$i*5},
  .mx-#{$i*5} {
    margin-left: $i*5px;
  }

  .me-#{$i*5},
  .mx-#{$i*5} {
    margin-right: $i*5px;
  }

  .pb-#{$i*5},
  .py-#{$i*5} {
    padding-bottom: $i*5px;
  }

  .p-#{$i*5} {
    padding: $i*5px;
  }

  .pt-#{$i*5},
  .py-#{$i*5} {
    padding-top: $i*5px;
  }

  .ps-#{$i*5},
  .px-#{$i*5} {
    padding-left: $i*5px;
  }

  .pe-#{$i*5},
  .px-#{$i*5} {
    padding-right: $i*5px;
  }
}

@for $i from 1 to 3 {
  .pb-#{$i*50}, .py-#{$i*50} {
    padding-bottom: $i*50px;
  }

  .pt-#{$i*50}, .py-#{$i*50} {
    padding-top: $i*50px;
  }

  .ps-#{$i*50} {
    padding-left: $i*50px;
  }

  .pe-#{$i*50} {
    padding-right: $i*50px;
  }

  .mb-#{$i*50} {
    margin-bottom: $i*50px;
  }

  .mt-#{$i*50} {
    margin-top: $i*50px;
  }

  .ms-#{$i*50} {
    margin-left: $i*50px;
  }

  .me-#{$i*50} {
    margin-right: $i*50px;
  }
}

@for $i from 6 to 10 {
  .opacity-#{$i} {
    opacity: $i*0.1;
  }
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-200 {
  padding-bottom: 200px;
}

.mt--100 {
  margin-top: -100px;
}