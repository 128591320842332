.navbar-brand {
  height: auto;
  font-family: $font-family-secondary;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $body-color;
  transition: all 0.3s;
  font-size: 24px;
  padding: 0;
  margin: 0;
  .dot {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    border-radius: 4px;
    display: inline-block;
    background: $primary;
    position: relative;
    transition: all 0.3s;
  }
  @media (max-width: 991px) {
    padding: 15px;
    font-size: 18px;
    line-height: 20px;
  }
}

.navbar-toggler {
  padding: 10px;
  border-radius: 5px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  span {
    display: block;
    width: 23px;
    height: 2px;
    margin-bottom: 6px;
    background: $dark;
    -webkit-transform-origin: 4px 0px;
    transform-origin: 4px 0px;
    transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
      -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(.collapsed) {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(4px, 0);

      &:last-child {
        opacity: 0;
      }

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(-3px, 5px);
      }
    }
  }
}

.navbar-fixed {
  padding: 10px 0px;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 991px) {
    padding: 15px 0;
  }
}

.navbar-default {
  .navbar-brand {
    color: $dark;
  }
  .navbar-nav {
    @media (min-width: 992px) {
      > li {
        & + li {
          margin-left: 15px;
        }

        > a {
          padding: 20px 15px !important;
        }
      }
    }
    @media (max-width: 991px) {
      margin: 8px 0px;
    }
    .nav-link {
      color: $menu-item;
      letter-spacing: 0.03em;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      font-family: $font-family-secondary;
      position: relative;
      outline: none;
      &:focus {
        color: $menu-item;
      }
      &:hover,
      &.active {
        color: $primary;
        opacity: 1;
      }
      @media (min-width: 992px) {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 10px;
          left: 10px;
          right: 10px;
          background: $primary;
          border-radius: 4px;
          height: 2px;
          transition: all 0.4s;
          transform: scaleX(0);
          transform-origin: right;
        }
        &.active {
          opacity: 1;
          color: $menu-item;
          &:after {
            transform: scaleX(1);
          }
        }
      }
      @media (min-width: 768px) {
        padding: 20px 15px;
      }
      @media (max-width: 991px) {
        position: relative;
        display: block;
        padding: 10px 15px;
      }
    }
  }
  .navbar-collapse {
    @media (max-width: 991px) {
      background: $white;
    }
  }
  @media (max-width: 991px) {
    padding: 10px 0px;
    & > .container {
      position: relative;
    }
  }
}

.navbar-light {
  .navbar-brand {
    color: $white;
    .dot {
      background: $white;
    }
    &:hover {
      color: $white;
    }
  }
  .navbar-toggler {
    border: none;
    @media (max-width: 991px) {
      span {
        background-color: $white;
      }
    }
  }
  .navbar-nav {
    .nav-link {
      @media (min-width: 992px) {
        color: $white;
        opacity: 0.8;
        &:focus {
          color: $white;
          opacity: 0.8;
        }
        &:hover {
          opacity: 1;
          color: $white;
        }
        &.active {
          color: $white;
          &:after {
            background: $white;
          }
        }
      }
    }
  }
}

.sticky {
  background-color: $white;
  width: 100%;
  box-shadow: 0 2px 2px rgba($black, 0.1);
  animation-name: slideInDown;
  animation-duration: 0.75s;
  animation-fill-mode: both;
  .navbar-brand {
    color: $black;
    .dot {
      background: $primary;
    }
    &:hover {
      color: $black;
    }
  }
  .navbar-toggler {
    span {
      background-color: $dark;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $menu-item;
      opacity: 1;
      &:focus {
        color: $menu-item;
        opacity: 1;
      }
      &:hover {
        opacity: 1;
        color: $primary;
      }
      &.active {
        color: $primary;
        &:after {
          background: $primary;
        }
      }
    }
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.right-bar {
  @media (max-width: 991px) {
    position: absolute;
    right: 60px;
    top: 4px;
  }
  @media (max-width: 767px) {
    top: 7px;
  }
  .login-btn {
    padding: 10px 24px;
    @media (max-width: 767px) {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      padding: 0;
    }
  }
}
