.client-list {
  padding-top: 30px;

  img {
    margin: 0 20px;
    max-width: 140px;

    @media (max-width: 991px) {
      margin: 0 8px 0 15px;
    }
    @media (max-width: 767px) {
      margin: 15px;
    }
  }
}


.testi-img {
  height: 60px;
  width: 60px !important;
}

.testi-text {
  line-height: 30px;
  letter-spacing: 0.02em;
  font-size: 16px;
  font-style: italic;
  color: $dark;
}

.testimonial-slider {
  h4 {
    font-weight: 600;
  }
}

.testimonial-slider-2 {
  .item {
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    h4 {
      font-weight: 600;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}

.testimonial-slider-3 {
  padding-bottom: 30px;
  display: block;
  .item {
    padding: 45px 30px;
    background: $light;
    border-radius: 8px;
  }
}
