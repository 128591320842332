


/* Google font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Manrope:wght@300;400;500&display=swap');


$primary:   #1d42d9;
$secondary: #343f52;
$success:   #45c4a0;
$info:      #54a8c7;
$warning:   #fab758;
$danger:    #dc3545;
$purple:    #9261c6;
$pink:      #e668b3;//ee76ad;
$white:     #ffffff;
$dark:      #343a40;
$light:     #f5faff;
$heading-color:   #1D2547;
$muted:     #98a6ad;
$black:     #000;
$blue: #051242;
$footer-bg: #292838;
// stylelint-disable
$gray-100: #F9F9F9;
$gray-200: #eee;
$gray-300: #e7e7e7;
$gray-400: #b7b6c7;
$gray-500: #98a6ad;
$gray-600: #767D8E;
$gray-700: #435966;
$gray-800: #3B3B40;
$gray-900: #212529;
$dark-nav-menu-item: #eee;
$border-color: #f1f1f1;
$scrollbarhvr: #f8fafc;
$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "purple": $purple,
    "pink": $pink,
    "white": $white,
    "light": $light,
    "blue": $blue
);
$brand-icons: (
  'twitter': #1da1f2,
  'facebook': #3b5999,
  'google': #dd4b39
);

$body-color:  #595959;

$menu-item: #182433;

$font-size-base : 16px;

$box-shadow:   0px 8px 35px 0px  rgba($gray-700, .08);
$box-shadow-2:   0 0px 10px 0 rgba($gray-700,.1);


$font-family-primary: 'Manrope', sans-serif;

// Secondary font
$font-family-secondary: "Montserrat",sans-serif;
$icon-font-family:  "remixicon";
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$img-path: "../images/";