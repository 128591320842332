
.team {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  h5 {
    color: $white;
  }
  .desc {
    background: $primary;
    padding: 15px 30px;
    text-align: center;
    color: $white;
    position: absolute;
    bottom: -56px;
    left: 0;
    right: 0;
    transition: all 0.3s;
  }
  &:hover {
    .desc {
      bottom: 0;
    }
  }
}

.member-intro {
  em {
    font-style: normal;
  }
}

.progress {
  &.progress-xs {
    height: 6px;
  }
  .progress-bar {
    background: $primary;
  }
}

.my-skills {
  .skill-item {
    margin-bottom: 30px;
    position: relative;
    display: flex;
    align-items: center;
    i {
      color: $black;
    }
  }
  .progress-item {
    width: 100%;
    padding-left: 15px;
    h5 {
      margin-bottom: 10px;
    }
  }
  .progress-bar {
    span {
      display: inline-block;
      width: 50px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background-color: $primary;
      color: $white;
      border-radius: 5px;
      font-weight: 700;
      position: absolute;
      top: 0;
      right: 0;
      &:after {
        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        border-width: 6px 6px 0;
        border-color: $primary transparent transparent;
        content: '';
        position: absolute;
        left: 50%;
        bottom: -6px;
        width: 0;
        height: 0;
        border-style: solid;
      }
    }
  }
}
