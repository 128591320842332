
.footer {
  padding: 20px 0px;
  border-top: 1px solid $gray-800;
  .logo {
    color: $white;
  }
}

.footer-one {
  color: $gray-400;
  padding-top: 60px;
  padding-bottom: 0px;
  background: $footer-bg;
  .navbar-brand  {
    margin-bottom: 15px;
  }
  h5 {
    color: $white;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  a {
    color: $gray-400;
    &:hover {
      color: $white;
    }
  }
  .footer-links {
    li {
      display: flex;
      padding:  8px 0;
    }
  }
  .subscribe-form {
    input {
      padding-left: 30px;
    }
  }
}


.footer-one-alt {
  margin-top: 50px;
  padding: 30px 0px;
  background: #111;
  @media (max-width: 767px) {
    
  }
}

.footer-social-one {
  a {
    border-radius: 50%;
    text-align: center;
    background-color: $white;
    width: 36px;
    display: block;
    height: 36px;
    line-height: 36px;
    color: $primary;
    font-weight: 500;
    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}
