
.circle-anim {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $white;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    -webkit-animation: videoBtnAnim 3s ease-in infinite;
    animation: videoBtnAnim 3s ease-in infinite;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 1px solid $white;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    -webkit-animation: videoBtnAnim 5s ease-in-out infinite;
    animation: videoBtnAnim 5s ease-in-out infinite;
    z-index: -1;
  }
}

.circle-anim-2 {
  position: relative;

  &:before, &:after {
    content: "";
    animation: ripple-1 2s infinite ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 100%;
    background: $info;
    z-index: -1;
  }
  &:after {
    opacity: 0.6;
    animation: ripple-2 2s infinite ease-in-out;
    animation-delay: 0.5s;
  }
  &.bg-white {
    &:before, &:after {
      background: $white;
    }
  }
}

.watch-vid {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.video-btn-circle-2 {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;

  i {
    line-height: 30px;
    font-size: 24px;
  }
}

@keyframes pulse-border {
  0% {
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }

  100% {
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}

@-webkit-keyframes videoBtnAnim {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes videoBtnAnim {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@-webkit-keyframes ripple-1{0%{transform:scale(1);opacity:1}100%{transform:scale(1.5);opacity:0}}@keyframes ripple-1{0%{transform:scale(1);opacity:1}100%{transform:scale(1.5);opacity:0}}
@-webkit-keyframes ripple-2{0%{transform:scale(1);opacity:1}100%{transform:scale(1.7);opacity:0}}@keyframes ripple-2{0%{transform:scale(1);opacity:1}100%{transform:scale(1.7);opacity:0}}

.video-bg {
  padding: 200px 0 180px;
  text-align: center;
  color: $white;
  border-radius: 32px;
  overflow: hidden;
}

.video-btn-circle {
  width: 80px;
  height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  color: $primary;
  background: $white;
  transition: background 0.4s;

  &:hover {
    background: $primary;
    color: $white;
  }

  i {
    line-height: 80px;
  }
}

.video-btn-over {
  position: relative;
  .video-btn-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
}