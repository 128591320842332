.contact-info {
  .address-info {
    display: flex;
    padding: 30px;
    border: 1px solid $border-color;
    & > i {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      flex-shrink: 0;
    }
    address,
    .text {
      margin-left: 15px;
    }
    strong {
      width: 100%;
      display: block;
      margin-bottom: 0.5rem;
      color: $dark;
      font-weight: 500;
    }
  }
}

.map {
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.contact-info-card {
  padding: 45px;
  @media (max-width: 767px) {
    padding: 45px 30px;
  }
  .social-icons {
    a {
      color: $white;
      font-size: 1.25rem;
      margin-right: 30px;
      height: auto;
      width: auto;
      &:hover {
        color: $white;
        background: none;
      }
    }
  }
}

.address-card {
  display: flex;
  .icon {
    width: 48px;
    height: 48px;
    background: $white;
    color: $dark;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px-rem-converter(20px);
    margin-right: 15px;
    flex-shrink: 0;
  }
  .text {
    color: $white;
    .label {
      font-weight: 700;
      display: block;
    }

    @media (max-width: 479px) {
      word-break: break-word;
    }
  }
}

.contact-box {
  padding: 45px;
  border-radius: 12px;
  background: $white;
  box-shadow: $box-shadow-2;
}
