.app-screens {
  .owl-carousel {
    padding: 140px 0;
    @media (max-width: 991px) {
      padding: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-image: url(../../images/app-screen-moc.png);
      background-position: center center;
      background-repeat: no-repeat;
      width: 305px;
      height: 651px;
      background-size: 100% auto;
      box-shadow: 0px 0px 60px 0px rgba($gray-700, 0.05);
      @media (max-width: 1440px) {
        -webkit-transform: translate(-50%, -49%) scaleY(0.95);
        transform: translate(-50%, -49%) scaleY(0.95);
      }
      @media (max-width: 1280px) {
        -webkit-transform: translate(-50%, -49%) scale(0.8);
        transform: translate(-50%, -49%) scale(0.8);
      }
      @media (max-width: 1024px) {
        -webkit-transform: translate(-50%, -49%) scale(0.7);
        transform: translate(-50%, -49%) scale(0.7);
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
    .owl-dots {
      bottom: -30px;
    }
  }
}

.work-nav {
  display: flex;
  margin-bottom: 35px;
  li {
    margin-right: 30px;
    margin-bottom: 15px;
    a {
      padding-bottom: 5px;
      position: relative;
      color: $menu-item;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $primary;
        content: "";
        transition: all 0.3s;
        transform: scale(0);
        transform-origin: right;
        height: 2px;
      }
    }
    .filtr-active {
      color: $primary;
      &:after {
        transform: scale(1);
      }
    }
  }
}

.work-grid-box-1 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .grid-image {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  .grid-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, 0.4);
    border-radius: 8px;
    color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    h4 {
      transition: all 0.25s ease-in-out;
      transform: translateY(-15px);
      transition-delay: 0.1s;
      opacity: 0;
      margin-bottom: 0.25rem;
      color: $white;
    }
    p {
      margin-bottom: 0;
      transition: all 0.25s ease-in-out;
      transform: translateY(15px);
      transition-delay: 0.1s;
      opacity: 0;
    }
  }
  &:hover {
    .grid-content {
      opacity: 1;
      visibility: visible;
      h4,
      p {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.project-details {
  .share-portfolio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    label {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.portfolio-slider {
  .owl-nav {
    position: static;
  }
  button.owl-prev, div.owl-prev,
  button.owl-next, div.owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  button.owl-next, div.owl-next {
    right: 0;
  }
  button.owl-prev, div.owl-prev {
    left: 0;
  }
}
