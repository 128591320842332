.flat-rounded-pagination {
    .page-item {
        &.disabled {
            .page-link {
                color: $gray-400;
                pointer-events: none;
                cursor: none;
                background-color: $white;
                border-color: $gray-400;
            }
        }
        &:not(.disabled) {
            &.active,
            &:hover {
                .page-link {
                    color: $white;
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px;
        border: none;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
    }
}
