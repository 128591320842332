.modal {
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    opacity: 1;
    color: $dark;
    transition: all 0.3s ease-out;
    &:hover {
      background: rgba($dark, 0.1);
    }
  }
}