::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #e8e8e8;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
::-webkit-scrollbar-thumb {
  background-image: -webkit-linear-gradient(45deg, $warning, $primary);
  background-image: -o-linear-gradient(45deg, $warning, $primary);
  background-image: linear-gradient(45deg, $warning, $primary);
}
/*Default css*/
::-moz-selection {
  background: rgba($black, 0.1) !important;
  color: $body-color !important;
}
::selection {
  background: rgba($black, 0.1) !important;
  color: $body-color !important;
}

body {
  font-size: $font-size-base;
  color: $body-color;
  font-family: $font-family-primary;
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-secondary;
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
  color: $heading-color;
}

b,
strong {
  font-weight: 500;
}

h1,
.h1 {
  font-size: 48px;
  line-height: 1.2;
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 1.5;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 1.2;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 1.5;
  }
}

h3,
.h3 {
  font-size: 32px;
  @media (max-width: 1025px) {
    font-size: 28px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 24px;
  @media (max-width: 1024px) {
    font-size: 22px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

h6,
.h6 {
  font-size: 16px;
}

::-moz-selection {
  background: $gray-700;
  color: $white;
}

::selection {
  background: $gray-700;
  color: $white;
}

.logo {
  height: 22px;
}

a {
  text-decoration: none !important;
  transition: all 400ms ease-in-out;
  color: $primary;
  font-weight: 500;
  &:hover {
    color: $primary;
  }
}

p {
  margin-bottom: 0;
}
