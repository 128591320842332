
.accordion {
  .accordion-item {
      box-shadow: none;
      margin-bottom: 15px;
      border-radius: 8px !important;
      border: 1px solid transparent;
      transition: all 0.3s;
      &.active {
          border-color: $primary;
      }
  }
  .accordion-header {
      background: none;
      border: none;
      .accordion-button {
          padding: 0;
          width: 100%;
          padding: 30px;
          text-transform: none;
          letter-spacing: 0;
          color: $body-color;
          display: block;
          box-shadow: none;
          background: none;
          &:after {
              display: none;
          }
          &:focus, &:hover {
              outline: none;
              text-decoration: none;
              box-shadow: none;
          }
          h6 {
              outline: none;
          }
      }
      h6 {
          position: relative;
          cursor: pointer;
          padding-right: 55px;
          &:before {
              content: "\EA13";
              position: absolute;
              top: 0;
              bottom: 0;
              width: 36px;
              height: 36px;
              margin: auto;
              right: 15px;
              text-align: center;
              line-height: 36px;
              border-radius: 100%;
              color: $primary;
              font-family: $icon-font-family;
              background: rgba($primary, 0.1);
              opacity: 0;
              transition: all 0.3s;
          }
          &:after {
              content: "\F1AF";
              position: absolute;
              top: 0;
              bottom: 0;
              width: 36px;
              height: 36px;
              margin: auto;
              right: 15px;
              text-align: center;
              line-height: 36px;
              border-radius: 100%;
              color: $white;
              font-family: $icon-font-family;
              background: $primary;
              opacity: 1;
              transition: all 0.3s;
          }
      }
      
      .collapsed {
          h6 {
              &:before {
                  opacity: 1;
              }
              &:after {
                  opacity: 0;
              }
          }
      }
  }
  .accordion-body {
      padding: 0 30px 30px;
  }
}

.highlight-accordion {
    .accordion-item {
        background: transparent;
        border: none;
    }
    .accordion-header {
        .accordion-button {
            padding: 0;
        }
        h6{
            padding-right: 0;
            padding-left: 30px;
            &:after, &:before {
                background: none;
                color: $primary;
                font-weight: 700;
                width: auto;
                height: 20px;
                right: auto;
                left: 0;
                line-height: 20px;
                font-size: 18px;
            }
        }
        h6:before {
            content: '\EA4E';
        }
        h6:after {
            content: '\EA78';
        }
    }
    .accordion-body {
        padding: 15px 0;
    }
}