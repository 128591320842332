.facts-box {
  margin-top: -52px;
  background: $white;
  box-shadow: 0 -3px 31px 0 rgba($black, 0.05), 0 6px 20px 0 rgba($black, 0.02);
  padding: 15px 30px;
  border-radius: 15px;
  position: relative;
  .fact {
    border-right: 1px solid $border-color;
    &:last-child {
      border-right: none;
    }
    @media (max-width: 767px) {
      &:nth-child(odd) {
        border-right: 1px solid $border-color;
      }
      &:nth-child(even) {
        border-right: none;
      }
    }
  }
}

.fun-fact-box {
  border: 2px solid $white;
  border-radius: 8px;
  color: $white;
  padding: 30px;
  @media (max-width: 991px) {
    padding: 30px 15px;
  }
  h3, h6 {
    color: $white;
  }
}


.feat-facts {
  display: table;
  width: 100%;
  margin-top: 20px;
}

.feat-facts {
  .feat-facts-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    h4 {
      font-weight: $font-weight-semibold;
    }

    p {
      font-style: italic;
    }
  }

  h2 {
    color: $primary;
  }
}

.fact-box {
  display: flex;
  padding: 30px;
  box-shadow: 0 .25rem 1.75rem rgba($dark,.07);
  i {
    width: 60px;
    height: 60px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  h3 {
    line-height: 1;
    font-weight: 700;
  }
  .text {
    flex: 1;
  }
}
