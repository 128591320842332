.page-wrapper {
    overflow: hidden;
}

.mh-100vh {
    min-height: 100vh;
}

.border-radius-2x {
    border-radius: 8px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-24 {
    font-size: 24px;
    @media (max-width: 767px) {
        font-size: 18px;
    }
}

.font-48 {
    font-size: 48px;
    line-height: 1.2;
    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 1.5;
    }
}

.spin {
    &:before {
        animation: spin 2s linear infinite;
        display: inline-block;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.center-page {
    float: none;
    margin: 0 auto;
}

.section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 0;
    &.pt-200 {
        padding-top: 200px;
    }
    &.pb-200 {
        padding-bottom: 200px;
    }
}

.section-md {
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px;
}

.section-lg {
    position: relative;
    padding-top: 180px;
    padding-bottom: 180px;
    &.pt-150 {
        padding-top: 150px;
    }
    &.pb-150 {
        padding-bottom: 150px;
    }
}

.section-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section-title {
    margin-bottom: 60px;
    &.mb-40 {
        margin-bottom: 40px;
    }
    .sub {
        font-size: 15px;
        color: $pink;
        margin-bottom: 0.5rem;
        font-weight: 500;
    }
    h2 {
        margin: 0;
    }
}

.line-list {
    li {
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
        &:last-child {
            border-bottom: none;
        }
    }
}

.highlight-list {
    display: flex;
    flex-wrap: wrap;
    li {
        padding: 8px 15px 8px 30px;
        position: relative;
        width: 50%;
        &:before {
            position: absolute;
            top: 15px;
            width: 20px;
            height: 20px;
            margin: auto;
            left: 0;
            line-height: 20px;
            font-family: "remixicon";
            transition: all 0.3s;
            content: "\EB7B";    
            color: $primary;
            display: flex;
            font-size: 14px;
            background: rgba($primary, 0.1);
            border-radius: 100%;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }
}

.social-icons {
    display: flex;
    ul {
        display: flex;
    }
    li {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    a,
    .social-icon {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: $body-color;
        margin-right: 10px;
        transition: all 0.3s linear;
        &:hover {
            color: $primary;
            background: $white;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    @each $key, $value in $brand-icons {
        .btn-#{$key} {
            &:hover {
                background: darken($value, 5);
                color: $white;
            }
        }
    }
    button.social-icon {
        border: none;
    }
}

@each $key, $value in $brand-icons {
    .btn-#{$key} {
        background: $value;
        color: $white;
    }
}

.social-white {
    a {
        color: $primary;
        background: $white;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
}

.font-weight-medium {
    font-weight: $font-weight-medium !important;
}

.avatar-sm {
    height: 16px;
    width: 16px;
}

.avatar-md {
    height: 32px;
    width: 32px;
}

.avatar-lg {
    height: 48px;
    width: 48px;
}

.avatar-xl {
    height: 64px;
    width: 64px;
}

.box-shadow {
    box-shadow: $box-shadow;
}

.shadow-icon {
    position: relative;
    transition: all 0.3s;
    margin-top: 15px;
    box-shadow: 0 0px 20px 0 rgba($gray-700, 0.15);
    i {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 100%;
        display: block;
        z-index: 1;
        transition: all 0.3s;
    }
}

.validation-error {
    font-size: 12px;
    color: $danger;
}

.avatar-title {
    align-items: center;
    display: flex;
    font-weight: $font-weight-medium;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
}

.shape > svg {
    transform: scale(2);
    width: 100%;
    height: auto;
    transform-origin: top center;
}

.shape-2 {
    position: absolute;
    right: 0;
    top: 0;
    img {
        width: 400px;
    }
}

.dot-shape {
    position: absolute;
    left: -1.75rem;
    top: -5rem;
    background-image: radial-gradient($primary 2px, transparent 2.5px);
    background-size: 0.75rem 0.75rem;
    width: 8rem;
    height: 10rem;
    z-index: -1;
}

.img-bg-shape {
    position: absolute;
    bottom: -2.25rem;
    right: -1.5rem;
    width: 85%;
    height: 90%;
    z-index: -1;
}

.bg-soft-warning,
.bg-warning {
    .dot-shape {
        background-image: radial-gradient($warning 2px, transparent 2.5px);
    }
}

.download-icon {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    line-height: 80px;
    font-size: 48px;
    text-align: center;
    display: inline-block;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba($dark, 0.7);
}

.bg-overlay-gradient {
    background-image: linear-gradient(to right, $warning, $danger);
}

.bg-overlay-gradient-reverse {
    background-image: linear-gradient(to right, #06398c, #245fbf);
}

.bg-overlay-gradient-2 {
    background-image: linear-gradient(to right, $primary, $pink);
}

.bg-overlay-gradient-reverse-2 {
    background-image: linear-gradient(to left, $primary, $pink);
}

.bg-img {
    background: url("../../images/bg-img-1.jpg") center;
    position: relative;
    background-size: cover;
}

.hero-3-bg {
    background: url("../../images/hero-3-bg.png") no-repeat center center / cover;
}

@for $i from 1 to 2 {
    .bg-#{$i} {
        background: url("../../images/bg-#{$i}.jpg") no-repeat center center / cover;
    }
}

.video-btn {
    color: $white !important;
    letter-spacing: 1px;
    padding-top: 20px;
    outline: none !important;
    box-shadow: none !important;
    i {
        margin-right: 7px;
        display: inline-block;
        width: 34px;
        height: 34px;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 30px;
        vertical-align: middle;
        text-align: center;
        font-size: 12px;
        padding-left: 3px;
        margin-left: -12px;
    }
}

.shape-bg-top-left {
    position: absolute;
    top: 0;
    left: -440px;
    width: 800px;
    height: 500px;
    z-index: -1;
}

.shape-bg-bottom-right {
    position: absolute;
    bottom: 0;
    right: -400px;
    width: 800px;
    height: 500px;
    z-index: -1;
}

.shape-square,
.shape-square-left {
    position: absolute;
    top: 100px;
    right: 75px;
    z-index: -1;
    &.top-0 {
        top: 0;
    }
    &.tobottom {
        bottom: 0;
        right: 75px;
        top: auto;
        @media (max-width: 767px) {
            right: 15px;
        }
    }
    img {
        width: 90%;
    }
    @media (max-width: 767px) {
        top: 0;
        right: 15px;
    }
}

.shape-square-left {
    right: auto;
    left: 75px;
    &.tobottom {
        bottom: 0;
        left: 75px;
        right: auto;
        top: auto;
        @media (max-width: 767px) {
            left: 15px;
        }
    }
    @media (max-width: 767px) {
        left: 15px;
    }
}

.shape-square-2,
.shape-square-2-left {
    position: absolute;
    top: 100px;
    right: 50px;
    z-index: -1;
    &.top-0 {
        top: 0;
    }
    &.tobottom {
        bottom: 0;
        right: 50px;
        top: auto;
    }
}

.shape-square-2-left {
    right: auto;
    left: 50px;
    &.top-0 {
        top: 0;
    }
    &.tobottom {
        bottom: 0;
        left: 50px;
        right: auto;
        top: auto;
    }
}

.square-dot-anim {
    img {
        -webkit-animation-name: squareMover;
        animation-name: squareMover;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-perspective: 100px;
        perspective: 100px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

.square-rotate-anim {
    img {
        -webkit-animation-name: squareRotate;
        animation-name: squareRotate;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-perspective: 100px;
        perspective: 100px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

@-webkit-keyframes squareMover {
    0%,
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
        transform: translate(0, 0) rotate(0);
    }
    20%,
    60% {
        -webkit-transform: translate(20px, 40px) rotate(180deg);
        transform: translate(20px, 40px) rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: translate(40px, 60px) rotate(0deg);
        transform: translate(40px, 60px) rotate(0deg);
    }
}

@keyframes squareMover {
    0%,
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
        transform: translate(0, 0) rotate(0);
    }
    20%,
    60% {
        -webkit-transform: translate(20px, 40px) rotate(180deg);
        transform: translate(20px, 40px) rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: translate(40px, 60px) rotate(0deg);
        transform: translate(40px, 60px) rotate(0deg);
    }
}

@-webkit-keyframes squareRotate {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    20%,
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes squareRotate {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    20%,
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.shape-square-anim {
    position: absolute;
    top: 100px;
    right: 75px;
    z-index: -1;
    &.tobottom {
        bottom: 0;
        right: 75px;
        top: auto;
    }
    img {
        width: 90%;
        -webkit-animation-name: rotate;
        animation-name: rotate;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-perspective: 100px;
        perspective: 100px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

.text-tran-box {
    background: $primary;
    background: linear-gradient(to left, $primary, $pink);
}

.text-transparent {
    background: #f2f6fa;
    color: #240e35;
    mix-blend-mode: lighten;
    overflow: hidden;
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 54px;
    font-weight: 600;
}

.text-gred {
    background-image: linear-gradient(to right, $warning, $danger);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.text-gred-2 {
    background-image: linear-gradient(to right, $primary, $pink);
}

.text-tran-box-dark {
    .text-transparent {
        color: $white;
        background: $dark;
        mix-blend-mode: darken;
    }
}
