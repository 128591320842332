.auth-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  .card {
    box-shadow: none;
  }
  .box-shadow {
    box-shadow: $box-shadow;
  }
  .navbar-brand {
    color: $black;
    &:hover {
      color: $black;
    }
    .dot:after {
      border-color: $white;
    }
  }
}

.auth-page-sidebar {
  padding: 0;
  background-image: url('../../images/c02.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  @media (max-width: 767px){
    border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  }
  .overlay {
    flex: 1;
    opacity: 0.4;
  }

  .auth-user-testimonial {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    text-align: center;
    color: $white;
    margin: 0 auto;
    padding: 0 1.75rem;
    @media (max-width: 767px) {
      position: static;
      padding: 50px;
    }
  }
}

.full-auth-screen{
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .backhome{
    top: 1rem;
    right: 1rem;
  }
}


.auth-modal {
  .modal-content {
    border-radius: 8px;
  }
  .modal-header {
    padding: 60px 60px 0 60px;
    border-bottom: none;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 767px) {
      padding: 60px 30px 0 30px;
    }
  }
  .modal-body {
    padding: 30px 60px 45px;
    @media (max-width: 767px) {
      padding: 30px 30px 45px;
    }
  }
  .form-control {
    padding-left: 25px;
  }
  .view-pass {
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      right: 0;
      bottom: 0;
      left: 14px;
      width: 1px;
      height: 21px;
      background: $danger;
      transform: scale(0) rotate(40deg);
      transition: all 0.3s ease-in-out;
      transform-origin: top;
    }
    &.active {
      &:after {
        transform: scale(1) rotate(40deg);
      }
    }
  }
  .login-border {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background: #e7e7e7;
  }
  .social-login {
    position: relative;
    background: #fff;
    padding: 0 12px;
    margin-bottom: 0;
    display: inline-block;
  }
}