.btn {
    padding: 11px 24px;
    text-transform: capitalize;
      font-weight: 400;
    position: relative;
    box-shadow: none;
    overflow: hidden;
    z-index: 1;
    &:after,
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 50%;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        z-index: -1;
    }
    &:before{ 
        top: 0;
        left: 100%;
    }
    &:after{ 
        bottom: 0;
        right: 100%;
    }
    &.light-effect {
        &:after,
        &:before {
            background: $white;
        }
    }
    &:hover, &:focus, &:active {
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
    &.no-bg {
        &:after, &:before {
            display: none;
        }
    }
}

.btn-sm {
    padding: 10px 20px;
}

@each $name, $value in $colors {
    .btn-#{$name} {
        color: $white;
        background: #{$value};
        border-color: #{$value};
        box-shadow: 0 3px 10px rgba($value, 0.4);
        @if($name == 'white') {
            color: $black;
        }
        &:hover,
        &:focus,
        &:active,
        &.active,
        &.focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            color: $white;
            background: #{$value};
            border-color: #{$value};
        }
        &:after, &:before {
            background: darken($value, 30);
            @if ($name== "light") {
                background: $white;
            }
            @if($name == 'white') {
                background: $body-color;
            }
        }
    }
    .btn-outline-#{$name} {
        color: #{$value};
        border-color: #{$value};
        &:hover,
        &:focus,
        &:active,
        &.active,
        &.focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            background: transparent;
            border-color: darken($value, 4%);
            color: $white;
            @if ($name== "light") {
                color: $gray-900;
                border-color: $gray-900;
            }
            @if ($name== "white") {
                color: $black;
            }
        }
        &:after, &:before {
            background: darken($value, 30);
            
            @if ($name== "light") {
                background: $white;
            }
            @if ($name== "white") {
                background: $white;
            }
        }
    }
    .btn-soft-#{$name} {
        background-color: rgba(($value), 0.2) !important;
        border-color: rgba(($value), 0.2) !important;
        color: #{$value} !important;
        &:hover,
        &:focus,
        &:active,
        &.active,
        &.focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            background: $value !important;
            border-color: $value !important;
            color: $white !important;
        }
    }
}

.btn-light {
    color: $gray-800;
    &:hover {
        color: $dark;
    }
}

.brand-btn {
    position: relative;
    padding-left: 60px;
    overflow: hidden;
    .brand-icon {
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        background-color: rgba($black, 0.3);
        width: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
