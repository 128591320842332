/*
@Author: Lettstart Design
@URL: https://lettstartdesign.com/


This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.

This files contents are outlined below.


1.  Bootstrap
2.  Plugins
    a. Owl Carousel
3.  Variable
4.  Spacing
5.  General 
6.  Helper
7.  Plugin Reset
8.  Bootstrap Element Reset
9.  Form Reset
10. Navbar
11. Home
12. Feature
13. Video
14. Fact
15. Pricing
16. Subscribe
17. Client
18. FAQ
19. Footer
20. Pages
    a. Works
    b. Blogs
    c. Auth
    d. Contact 
    e. Element
    f. Teams
21. Responsive
*/

//1 Bootstrap css
@import 'bootstrap/scss/bootstrap';


//3 variables
@import "variables";

//4 spacing 
@import "utilities/spacing";

//5 reset

@import "utilities/reset";

//5 general
@import "utilities/general";

//6 helper
@import "utilities/helpers";


//2.a. Owl carousel plugin
@import 'plugins/owl-carousel';
@import 'plugins/swiper';
@import 'plugins/aos';

//8 bootstrap elements reset css
@import "./components/card";
@import "./components/carousel";
@import "./components/paginations";
@import "./components/buttons";
@import "./components/backgrounds";
@import "./components/texts";
@import "./components/accordion";
@import "./components/forms";
@import "./components/modal";

//9 form reset css

//10 navbar css
@import "shared/navbar";

//11 home section
@import "sections/home";

//12 feature section
@import "sections/features";

//13 video section
@import "sections/video";

//14 fact section
@import "sections/facts";

//15 pricing section
@import "sections/pricing";

//16 subscribe section
@import "sections/subscribe";

//17 client section
@import "sections/clients";


//19 footer section
@import "shared/footer";

/*-------------------- 
--------- 20 Pages css -----------
-----------*/

//a works page
@import "pages/works";

//b blog page
@import "pages/blogs";

@import "pages/blog-details";

//c auth pages
@import "pages/auth";

//d contact us
@import "pages/contactus";

//e element page css
@import "elements";

//f teams page
@import "pages/teams";
