.blogs-details {
  .blog-image {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      text-align: left;
      flex-wrap: wrap;
    }
    img {
      border-radius: 8px;
    }
    .blog-header {
      position: absolute;
      top: 50%;
      background: $white;
      padding: 30px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      border-radius: 4px;
      @media (max-width: 767px) {
        position: static;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        padding: 30px 0 15px 0;
        background: transparent;
        width: 100%;
        h1 {
          font-size: 24px;
        }
      }
    }
  }
}

.blog-content {
  position: relative;
  .blockquote {
    border: 2px solid $primary;
    background-color: transparent;
    padding: 0 30px 30px;
    p {
      font-size: 18px;
    }
    h4 {
      font-weight: 500;
    }
    .quote-icon {
      font-size: 20px;
      color: $white;
      height: 45px;
      width: 45px;
      background-color: $primary;
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -23px;
      margin-bottom: 18px;
    }
  }
  .blog-share {
    @media (min-width: 1025px) {
      position: absolute;
      top: 0;
      left: -100px;
      top: 0;
      h5 {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 1024px) {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      ul {
        display: flex;
        margin-left: 15px;
        margin-bottom: 0;
        li {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.tags-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .tag-btn {
    font-size: 15px;
    line-height: 1;
    background-color: $light;
    color: $dark;
    padding: 15px;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    margin-right: 15px;
    margin-bottom: 15px;

    &:hover {
      background-color: $gray-300;
    }
  }
}

.blog-footer-author {
  text-align: center;
  background-color: $light;
  position: relative;
  padding: 30px;

  .author-avatar {
    height: 120px;
    width: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: -80px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.blog-footer-related {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .related-item {
    flex: 1;

    a {
      color: $dark;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;

      i {
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }

    img {
      width: 100px;
    }

    &:hover {
      a {
        color: $primary;

        i {
          margin-right: 1rem;
        }
      }
    }

    &.next {
      &:hover {
        a {
          i {
            margin-left: 1rem;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.blog-footer-comment {
  .media > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .comment-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
