@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';

.owl-theme {
  .owl-nav {
    [class*=owl-]:hover {
      background: $primary;
    }
  }
}

.owl-carousel {
	.owl-dots {
		position: absolute;
		bottom: -50px;
		left: 0;
		right: 0;
		text-align: center;
		.owl-dot {
			width: 12px;
			height: 12px;
			margin-right: 5px;
			display: inline-block;
      border-radius: 16px;
			border: 1px solid $body-color;
      transition: all 0.3s ease-in-out;
			&.active, &:focus {
				background: $primary;
				border-color: $primary;
        width: 20px;
			}
			&:focus {
				outline: none;
      }
      span {
        display: none;
      }
		}
  }
  .owl-nav {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    div.owl-prev, div.owl-next {
      width: 48px;
      height: 48px;
      margin: auto 0;
      background: $white;
      font-size: 24px;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 100%;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    button.owl-next, div.owl-next {
      margin-left: 15px;
    }
    .disabled {
      opacity: 0.6;
    }
  }
}
