button {
  &:focus {
    outline: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-over-bg {
  z-index: 1;
  position: relative;
}

.media-body {
  flex: 1;
}

.link {
  padding-bottom: 5px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    background: $primary;
    transform: scale(0);
    transition: transform 0.25s;
    transform-origin: right;
  }
  &:hover:after {
    transform: scale(1)
  }
}

.mfp-image-holder, .mfp-iframe-holder {
  .mfp-close {
    width: auto;
  }
}

.breadcrumb {
  background: none;
  justify-content: center;
  margin-bottom: 0;
  li {
    color: $white;
    font-weight: 500;
    &:after {
      content: "/";
      padding:0 10px;
      text-align: right;
    }
    &:last-child:after {
      display: none;
    }
  }
  a {
    color: rgba($white, 0.7);
    &:hover {
      color: $white;
    }
  }
}

.border-2x {
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar {
  width: 13px
}

.scrollbar::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  min-height: 84px;
  background-color: rgba($black,.4);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba($black,.5);
}

.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(248,250,252,0);
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: $scrollbarhvr;
}

.abs-svg-top, .abs-svg-bottom {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
}

.abs-svg-top {
  top: 0;
}


.abs-svg-bottom {
  bottom: 0;
}