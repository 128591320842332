.sidebar-nav {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  background: $black;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  transition: all 0.4s;
  transform: translateX(105%);
  .navbar-brand {
    color: $white;
    &:hover {
      color: $white;
    }
    .dot:after {
      border-color: $white;
    }
  }
  .navbar-nav {
    margin-top: 60px;
    li {
      width: 100%;
      border-bottom: 1px solid rgba($border-color, 0.1);
      & + li {
        margin-left: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      .nav-link {
        padding: 10px 0 !important;
        color: $dark-nav-menu-item !important;
        opacity: 1;
        font-weight: 400;
        text-transform: unset;
      }
    }
  }
  &.show {
    transform: translateX(0);
  }
}

.sidebar-nav-left {
  right: auto;
  left: 0;
  transform: translateX(-105%);
}
